import React, { useState, useEffect } from 'react';
import './App.css';

function App() {


  useEffect(()=>{

    window.location.href = "https://www.linkedin.com/in/simonecarcone/";

  }, []);


  return <div></div>;


}//App

export default App;
